import React, { useState, useEffect } from "react";
import "../login/login.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/parliament-logo.png";
import bg from "../../assets/images/login-bg.png";
import closeIcon from "../../assets/images/circle-close.svg";
import eyeIcon from "../../assets/images/eye.svg";
import eyeIcon2 from "../../assets/images/slash-eye.svg";
import Api from "../Api/Api";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialTwitter, LoginSocialLinkedin } from "reactjs-social-login";
import { FaGoogle } from "react-icons/fa";
import googleLogo from "../../assets/images/google.png";
import fbLogo from "../../assets/images/facebook.png";
import twitterLogo from "../../assets/images/twitter.png";
import linkedinLogo from "../../assets/images/linkedin.png";
function Login({ setlogged_in }) {
  const [logged_in, setlogged_inLocal] = useState(false);
  const [emails, setEmails] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const { SignIn, SocialMediaRegister } = Api();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const notify = () => toast("Wow so easy!");
  const user_role = localStorage.getItem("user_role");
  const location = useLocation();
  const isAdminLogin = location.pathname === "/admin/login";
  // const LoginSuccess = () => toast("Login successful! Redirecting to your dashboard...!");

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem("logged_in");
    if (storedLoggedIn) {
      setlogged_inLocal(JSON.parse(storedLoggedIn));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("logged_in", JSON.stringify(logged_in));
  }, [logged_in]);

  // const handleLogin = () => {
  //     setlogged_inLocal(true);
  // };
  // const handleLogout = () => {
  //     setlogged_inLocal(false);
  // };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const clearEmail = () => {
    // setEmail('');
    setValue("email", "");
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const response = await SignIn(data);
      console.log("login", response);
      if (response.success) {
        toast.success(response.message);
        setlogged_in(true);
        setlogged_inLocal(true);
        // document.cookie = `auth_token=${response.parameters.token}; path=/`;
        localStorage.setItem("auth_token", response.parameters.token);
        localStorage.setItem("user_id", response.parameters.user.id);
        localStorage.setItem("email_id", response.parameters.user.email);
        localStorage.setItem("user_name", response.parameters.user.name);
        localStorage.setItem("user_role", response.parameters.user.role);
        localStorage.setItem("logged_in", response.success);
        if (user_role === "ADMIN") {
          navigate("/admin/dashboard");
        } else {
          navigate("/user/livevideos");
        }
        // console.log("login response", response.parameters.token);

        // localStorage.setItem("auth_token", response.parameters.token);

        // } else {
        //   // Handle login failure
        //   // toast.error(response.message);
        //   // setPasswordError(response.parameters.password[0]);
        //   toast.error(response.message);
        //   setValue("email", "");
        //   setValue("password", "");

        //   setPasswordError(response.parameters.password[0]);
        // }
      }
      if (response.success == false) {
        toast.error(response.message);
        setValue("email", "");
        setValue("password", "");
        setPasswordError(response.parameters.password[0]);
      }
    } catch (error) {
      // setDisble(false);
      console.log("Error message:", error.message);
      if (error.message && error.message.status === 400) {
        // If the error is due to a bad request (status 400),
        // access the error message from the response data
        console.log("Error messagesd:", error);
      }
    }
  };


  // Google login handler
  const handleGoogleLoginSuccess = async ({ provider, data }) => {
    try {
      console.log(provider, data);
      toast.success("Google login successful! Redirecting...");

      // Send the Google user data to your backend registration API
      const response = await SocialMediaRegister({
        // auth_token:data.id_token || data.access_token,
        name: data.name,
        provider: provider,
        email: data.email,
      });

      if (response.success) {
        setlogged_in(true);
        localStorage.setItem("auth_token", response.parameters.token);
        localStorage.setItem("user_id", response.parameters.user.id);
        localStorage.setItem("email_id", response.parameters.user.email);
        localStorage.setItem("user_name", response.parameters.user.name);
        localStorage.setItem("user_role", response.parameters.user.role);
        localStorage.setItem("logged_in", response.success);
        // localStorage.setItem("user_picture", data.picture);
        if (user_role === "ADMIN") {
          navigate("/admin/dashboard");
        } else {
          navigate("/user/livevideos");
        }
        // toast.success(response.message);

      } else {
        toast.error("Google login failed during registration.");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Google login failed. Please try again.");
    }
  };
  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
    toast.error("Google login failed. Please try again.");
  };

  return (
    <>
      <style>{`
        .footer_section {
          display: none;
        }
      
      `}</style>
      <div className="main_body mobile_login">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-6 sm-order-2">
              <div className="login_left text-center">
                <div className="login_logo mb-3">
                  <img src={logo} alt="logo" />
                </div>
                <div className="title_part">
                  Our Meetings are available on <br></br>these Platforms
                </div>
                <div className="login_bg mobile_bg">
                  <img src={bg} alt="login" className="w-85 mobi_bg" />
                </div>
                <div className="copy_right d-sm-block d-md-none d-lg-none text-center t_small_none">
                  Copyright 2024. All Rights Reserved
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <div className="form_container">
                <div className="login_logo-right mb-3">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login_header-menu mb-3">
                  <ul>
                    {location.pathname !== "/admin/login" && (
                      <div className="login_header-menu mb-3">
                        <ul>
                          <li>
                            <Link to="/register">Register</Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </ul>
                </div>

                <div className="login_form-inner">
                  <div className="login_title">Welcome Back !!!</div>
                  <div className="login_sm-title">Login to your Account...</div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input_from position-relative">
                      <input
                        type="email"
                        placeholder="Enter Email"
                        // value={email}
                        onChange={handleChangeEmail}
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                      {errors.email && (
                        <span className="error_message">
                          {errors.email.message}
                        </span>
                      )}
                      {email == "" && (
                        <span className="text_close" onClick={clearEmail}>
                          <img src={closeIcon} alt="close" />
                        </span>
                      )}
                    </div>
                    <div className="input_from position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        })}
                      />
                      {errors.password && (
                        <span className="error_message">
                          {errors.password.message}
                        </span>
                      )}

                      <span
                        className="text_visible"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <img src={eyeIcon} alt="hide" />
                        ) : (
                          <img src={eyeIcon2} alt="show" />
                        )}
                      </span>
                    </div>
                    {/* <span className='text-danger'>{passwordError}</span> */}
                    <div className="forgot_password">
                      {isAdminLogin ? (
                        <Link to="/forgot-password">Recover Password ?</Link>
                      ) : (
                        <Link to="/forgot-password">Recover Password ?</Link>
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="login_submit btn w-100 text-white"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>


                  <div className="or_divide position-relative w-100 text-center my-3">
                    <span className="position-relative d-inlineblock px-3">Or do it via other accounts</span>
                  </div>

                  <div className="google_login d-flex align-items-center justify-content-center gap-2">

                    <LoginSocialGoogle
                      client_id="652369120452-b4tjpuvs7s0fd27659ueb527for95kcd.apps.googleusercontent.com"  
                      // client_id="http://100074658846-ahqorpi7aosn1ot3f2f85tu1vng76nqv.apps.googleusercontent.com"
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      onResolve={handleGoogleLoginSuccess}
                      onReject={handleGoogleLoginFailure}
                    >
                      <button className="btn border d-flex align-items-center justify-content-center p-0 gap-2">
                        <img src={googleLogo} alt="google" />
                      </button>
                    </LoginSocialGoogle>

                    <LoginSocialFacebook
                      appId="YOUR_FACEBOOK_APP_ID"  // Replace with your App ID
                    // onSuccess={handleSuccessFacebook}
                    // onFailure={handleFailureFacebook}
                    >
                      <button className="btn border d-flex align-items-center justify-content-center p-0 gap-2">
                        <img src={fbLogo} alt="facebook" />
                      </button>
                    </LoginSocialFacebook>

                    <LoginSocialTwitter
                      consumerKey="F56QeAvBXIQZBdVePM8hmOsvl" // Replace with your Twitter API key
                      consumerSecret="TmtifVhjjzeSUEMC7DWNAbd8pRUZbR7DEdmzVlmbmGsIJKK4Oz" // Replace with your Twitter API secret
                      callbackUrl="YOUR_CALLBACK_URL" // Replace with your callback URL
                    // onSuccess={handleSuccessTwitter}
                    // onFailure={handleFailureTwitter}
                    >
                      <button className="btn border d-flex align-items-center justify-content-center p-0 gap-2">
                        <img src={twitterLogo} alt="twitter" />
                      </button>
                    </LoginSocialTwitter>
                    <LoginSocialLinkedin>
                    <button className="btn border d-flex align-items-center justify-content-center p-0 gap-2">
                        <img src={linkedinLogo} alt="linkedin" />
                      </button>
                    </LoginSocialLinkedin>


                  </div>

                </div>

                <div className="copy_right text-center my-4 d-none d-sm-none d-md-block d-lg-block">
                  Copyright 2024. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
