import React, { useState, useEffect } from "react";
import meetingImg from "../../assets/images/meeting.png";
import { Link, useParams } from "react-router-dom";
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import copyIcon from "../../assets/images/copy_icon.png";
import shareIcon from "../../assets/images/share_icon.png";
import fbSmIcon from "../../assets/images/fb_icon1.png";
import whatsappIcon from "../../assets/images/whatsapp_icon.png";
import videoImg from "../../assets/images/video_thum.png";
import youtubeIcon from "../../assets/images/youtube_icon.png";
import Api from "../Api/Api";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SkeletonLoader from "../util/SkeletonLoader";
import Modal from "../LiveVideos/Modal";


function MeetingDetail() {
  const { videoId } = useParams();
  const [loading, setLoading] = useState(true);
  const moment = require("moment");
  const User_ID = localStorage.getItem("user_id");
  const [meetDetails, setMeetDetails] = useState();
  const [YoutubeLink, setYoutubeLink] = useState();
  const [FacebookLink, setFacebookLink] = useState();
  const [TwitterLink, setTwitterLink] = useState();
  const [copied, setCopied] = useState(false);
  const {
    MeetingDetails,
    VideoView,
    ReportTitle,
    AddReport,
    count_channel_click,
  } = Api();
  const [ReportData, setReportData] = useState([]);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },trigger
  } = useForm();
  const [showTextarea, setShowTextarea] = useState(false);
  const userRole = localStorage.getItem("user_role");
  const isAdmin = userRole === "ADMIN";
  console.log("isAdmin:", isAdmin);
  useEffect(() => {
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
    GetDetails();
    VideoViewCount();
    ReportList();
  }, []);

  const GetDetails = async () => {
    try {
      let data = await MeetingDetails(videoId);
      // setcatalogData(data.categories);
      // console.log("meeting", data.parameters);
      setMeetDetails(data.parameters);
      setYoutubeLink(data.parameters.youtube_link);
      setFacebookLink(data.parameters.facebook_link);
      setTwitterLink(data.parameters.twitter_link);
      setLoading(false);
      // setMeetingData(data.parameters.data);
    } catch (err) {
      console.log(err);
    }
  };

  const ReportList = async () => {
    try {
      let data = await ReportTitle();
      console.log("ReportList", data.parameters);
      setReportData(data.parameters);
      // setMeetingData(data.parameters.data);
    } catch (err) {
      console.log(err);
    }
  };

  const VideoViewCount = async () => {
    try {
      let data = await VideoView(videoId);
      console.log(data);
      // setMeetingData(data.parameters.data);
    } catch (err) {
      console.log(err);
    }
  };

  const shareOnWhatsApp = () => {
    const url = encodeURIComponent(meetDetails.youtube_link);
    const text = encodeURIComponent("Check out this link!");
    const whatsappUrl = `https://wa.me/?text=${text}%20${url}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const url = encodeURIComponent(meetDetails.youtube_link);
    const text = encodeURIComponent("Check out this link!");
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
    window.open(facebookUrl, "_blank");
  };

  const shareOnTwitterIcon = () => {
    const url = encodeURIComponent(meetDetails.youtube_link);
    const text = encodeURIComponent("Check out this link!");
    const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnWhatsAppFace = () => {
    const url = encodeURIComponent(meetDetails.facebook_link);
    const text = encodeURIComponent("Check out this link!");
    const whatsappUrl = `https://wa.me/?text=${text}%20${url}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnFacebookFace = () => {
    const url = encodeURIComponent(meetDetails.facebook_link);
    const text = encodeURIComponent("Check out this link!");
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
    window.open(facebookUrl, "_blank");
  };

  const shareOnTwitterIconFace = () => {
    const url = encodeURIComponent(meetDetails.facebook_link);
    const text = encodeURIComponent("Check out this link!");
    const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    window.open(twitterUrl, "_blank");
  };

  const shareOnWhatsAppTwitter = () => {
    const url = encodeURIComponent(meetDetails.twitter_link);
    const text = encodeURIComponent("Check out this link!");
    const whatsappUrl = `https://wa.me/?text=${text}%20${url}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnFacebookTwitter = () => {
    const url = encodeURIComponent(meetDetails.twitter_link);
    const text = encodeURIComponent("Check out this link!");
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
    window.open(facebookUrl, "_blank");
  };

  const shareOnTwitterIconTwitter = () => {
    const url = encodeURIComponent(meetDetails.twitter_link);
    const text = encodeURIComponent("Check out this link!");
    const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    window.open(twitterUrl, "_blank");
  };

  const copyYoutubeLink = () => {
    const urlValue = meetDetails.youtube_link;

    navigator.clipboard
      .writeText(urlValue)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const copyFacebookLink = () => {
    const urlValue = meetDetails.facebook_link;
    navigator.clipboard
      .writeText(urlValue)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const handleClickcount = async ({ channel, meeting_id }) => {
    await handleCount({
      user_id: User_ID,
      channel: channel,
      meeting_id: meeting_id,
    });
  };
  console.log(meetDetails);
  const handleCount = async ({ user_id, channel, meeting_id }) => {
    let values = {
      user_id: user_id,
      channel: channel,
      meeting_id: meeting_id, //vedio id
    };
    console.log(values);
    try {
      const response = await count_channel_click(values);
    } catch (error) {
      console.log(error);
    }
  };

  const copyTwitterLink = () => {
    const urlValue = meetDetails.twitter_link;
    navigator.clipboard
      .writeText(urlValue)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    const [id, reportTitle] = selectedValue.split("|");
    // Now you have 'id' and 'reportTitle' accessible for further use
    console.log("id:", id);
    console.log("reportTitle:", reportTitle);

    if (reportTitle === "Others") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
    }
  };

  // const onSubmit = async (data) => {
  //   try {
  //     const [id, report_title] = data.report.split("|");
  //     const comments = data.comments;
  //     const response = await AddReport({ id, report_title, comments, videoId });
  //     if (response.success) {
  //       toast.success(response.message);
  //       reset();
  //       setShowTextarea(false);
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) { }
  // };



  const onSubmit = async (data) => {
    try {
      const [id, report_title] = data.report.split("|");
      const comments = data.comments || "No comments provided"; // Optional comments
      // const phoneNumber = "+27726209330";
      const videoTitle = meetDetails?.video_title

      const message = `*Video Title:* ${videoTitle}\n*Report Title:* ${report_title}\n*Report ID:* ${id}\n*Comments:* ${comments}`;

      // const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

      const response = await AddReport({ id, report_title, comments, videoId });
      if (response.success) {
        toast.success(response.message);
        reset();
        setShowTextarea(false);
        // window.open(whatsappURL, '_blank');
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };


  const handleSendWhatsapp = async () => {
    const isValid = await trigger(["report", "comments"]); // Validate specific fields
  
    if (isValid) {
      try {
        const formData = getValues(); // Fetch form data using getValues
        const [id, report_title] = formData.report.split("|");
        const comments = formData.comments || "No comments provided"; // Optional comments
        const phoneNumber = "+27726209330";
        const videoTitle = meetDetails?.video_title;
  
        const message = `*Meeting Report*\n\n*Video Title:* ${videoTitle}\n*Report Title:* ${report_title}\n*Report ID:* ${id}\n*Comments:* ${comments}`;

  
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, "_blank");
        reset();
      } catch (error) {
        toast.error("Something went wrong.");
      }
    } else {
      toast.error("Please fill out the required fields.");
    }
  };



  const user_role = localStorage.getItem("user_role");

  const url = window.location.href;
  // Split the URL by '/'
  const urlParts = url.split("/");
  // Find the index of 'livevideos' in the URL
  //user
  const livevideosIndex = urlParts.indexOf("livevideos");
  const Preveasmeeting = urlParts.indexOf("videos");
  const LikedMeeting = urlParts.indexOf("likedvideos");
  //admin
  const AdminPrevMeeting = urlParts.indexOf("meetings");
  const AdminLikedMeeting = urlParts.indexOf("likedmeetings");
  const Subscribed = urlParts.indexOf("subcribedmeeeting");
  const Reports = urlParts.indexOf("reports");
  const allMeeting = urlParts.indexOf("all-meeting");

  // Get the part after 'livevideos'
  //user
  const livevideos = urlParts[livevideosIndex];
  const Previous_meeting = urlParts[Preveasmeeting];
  const liked_meeting = urlParts[LikedMeeting];

  //admin
  const admin_prvmeeting = urlParts[AdminPrevMeeting];
  const admin_likedmeeting = urlParts[AdminLikedMeeting];
  const admin_subcribed = urlParts[Subscribed];
  const admin_report = urlParts[Reports];
  const admin_all_meeting = urlParts[allMeeting];
  console.log(admin_all_meeting);




  const [embedLink, setEmbedLink] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [socialType, setSocialType] = useState(null);
  const handleOpenModal = (link, type) => {
    let embedUrl = null;

    if (type === 'youtube') {
      const videoId = link?.split('v=')[1]?.split('&')[0];
      if (videoId) {
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (type === 'facebook') {
      embedUrl = link; 
      // const eventId = link?.split('/videos/')[1]?.split('/')[0];
      const eventId = link?.split('/').pop();
      if (eventId) {
        embedUrl = eventId;
        console.log('Event ID:', eventId); // Logs the event ID for further use
      }
    } else if (type === 'twitter') {
      embedUrl = link;
    }

    if (embedUrl) {
      setEmbedLink(embedUrl);
      setSocialType(type);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmbedLink(null);
  };


  return (
    <>
      <style>{`
        .searces_bar {
          display: none;
        }
      
      `}</style>

      <div className="page_container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb">
                <ul>
                  {/* <li className="active">
                    <Link to="#">Home</Link>
                  </li> */}
                  {livevideos && (
                    <li className="active">
                      <Link
                        to={`${user_role === "ADMIN"
                          ? "/admin/meetings"
                          : "/user/livevideos"
                          } `}
                      >
                        Live Meetings
                      </Link>
                    </li>
                  )}
                  {Previous_meeting && (
                    <li className="active">
                      <Link
                        to={`${user_role === "ADMIN"
                          ? "/admin/meetings"
                          : "/user/videos"
                          } `}
                      >
                        Previous Meetings
                      </Link>
                    </li>
                  )}
                  {admin_all_meeting && (
                    <li className="active">
                      <Link
                        to={`${user_role === "ADMIN" && "/admin/all-meetings"} `}
                      >
                        All Meetings
                      </Link>
                    </li>
                  )}
                  {liked_meeting && (
                    <li className="active">
                      <Link
                        to={`${user_role === "ADMIN"
                          ? "/admin/meetings"
                          : "/user/likedvideos"
                          } `}
                      >
                        Liked Meetings
                      </Link>
                    </li>
                  )}
                  {admin_prvmeeting && (
                    <li className="active">
                      <Link
                        to={`${user_role === "ADMIN"
                          ? "/admin/meetings"
                          : "/user/likedvideos"
                          } `}
                      >
                        Previous Meetings
                      </Link>
                    </li>
                  )}
                  {admin_likedmeeting && (
                    <li className="active">
                      <Link to={"/admin/likedmeetings"}>Liked Meetings</Link>
                    </li>
                  )}
                  {admin_subcribed && (
                    <li className="active">
                      <Link to={"/admin/subcribedmeeeting"}>
                        Subscribed Meetings
                      </Link>
                    </li>
                  )}
                  {admin_report && (
                    <li className="active">
                      <Link to={"/admin/reports"}>Report</Link>
                    </li>
                  )}
                  <li>{meetDetails?.video_title}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="page_title">Meeting Details</div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="meeting_card">
                <div className="row">
                  <div className="col-12">
                    {loading ? <SkeletonLoader /> : <>
                      <h3 className="meet_title">{meetDetails?.video_title}</h3>
                      <div className="d-flex align-items-center justify-content-between py-2">
                        <p className="meet_schedule m-0 p-0">
                          {meetDetails?.video_date && meetDetails?.video_date}{" "}
                          {meetDetails?.from_time
                            ? moment(meetDetails?.from_time, "HH:mm:ss").format(
                              "HH:mm"
                            )
                            : "00:00"}
                          <span className="px-2">to</span>
                          {meetDetails?.to_time
                            ? moment(meetDetails?.to_time, "HH:mm:ss").format(
                              "HH:mm"
                            )
                            : "00:00"}
                        </p>

                        <div>
                          <button
                            className={
                              meetDetails?.status === "COMPLETED"
                                ? " custom_btn-success rounded-left rounded-right rounded-top rounded-bottom ws-75 "
                                : meetDetails?.status === "UPCOMING"
                                  ? " custom_btn-info rounded-left rounded-right rounded-top rounded-bottom ws-75"
                                  : " custom_btn-warning rounded-left rounded-right rounded-top rounded-bottom ws-75"
                            }
                          >
                            {meetDetails?.status === "COMPLETED"
                              ? "Completed"
                              : meetDetails?.status === "UPCOMING"
                                ? "Upcoming"
                                : "Live"}
                          </button>
                        </div>
                      </div>
                      <div className="meet_image mb-4">
                        {<img
                          src={meetDetails?.cover_link ? meetDetails?.cover_link : videoImg}
                          alt="meeting"
                          loading="lazy"
                        />}
                        {/* {!meetDetails?.cover_link && <img src={videoImg} />} */}
                      </div>
                      <div className="meet_description mb-4">
                        <p>{meetDetails?.video_description}</p>
                      </div>
                      <div className="meet_description mb-4">
                        <h3 className="meet_description-title">
                          To Watch in Social Media Platforms
                        </h3>

                        {meetDetails?.youtube_link ? (
                          <ul className="meet_media mb-2">
                            <li>
                              <img src={playIcon} alt="play" />
                            </li>
                            <li onClick={() => handleOpenModal(meetDetails.youtube_link, 'youtube')}>
                              {/* <Link
                              to={meetDetails.youtube_link}
                              className="link_control"
                              target="_blank"
                              onClick={() =>
                                handleClickcount({
                                  meeting_id: meetDetails.id,
                                  channel: "YOUTUBE",
                                })
                              }
                            > */}
                              {meetDetails.youtube_link}
                              {/* </Link> */}
                            </li>
                            <li>
                              <div
                                className="share_btn"
                                onClick={copyYoutubeLink}
                              >
                                <img src={copyIcon} alt="copy" />
                              </div>
                            </li>
                            <li>
                              <button
                                className="share_btn"
                                type="button"
                                id="shareDropdownButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={shareIcon} alt="copy" />
                              </button>
                              <ul
                                className="share_dropdown dropdown-menu"
                                aria-labelledby="shareDropdownButton"
                              >
                                <li className="share_title text-center">
                                  Share link via
                                </li>
                                <li className="social_list">
                                  <span>
                                    <div>
                                      <img
                                        src={whatsappIcon}
                                        onClick={shareOnWhatsApp}
                                        alt="whatsappIcon"
                                      />
                                    </div>
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={fbSmIcon}
                                        onClick={shareOnFacebook}
                                        alt="fbIcon"
                                      />
                                    </div>
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={twitterIcon}
                                        onClick={shareOnTwitterIcon}
                                        alt="twitterIcon"
                                      />
                                    </div>
                                  </span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : null}
                        {meetDetails?.facebook_link ? (
                          <ul className="meet_media mb-2">
                            <li>
                              <img src={fbIcon} alt="play" />
                            </li>
                            <li 
                            onClick={() => handleOpenModal(meetDetails?.facebook_link, 'facebook')}
                            >
                              {/* <Link
                              to={meetDetails?.facebook_link}
                              className="link_control"
                              target="_blank"
                              onClick={() =>
                                handleClickcount({
                                  meeting_id: meetDetails.id,
                                  channel: "FACEBOOK",
                                })
                              }
                            > */}
                              {meetDetails?.facebook_link}
                              {/* </Link>{" "} */}
                            </li>
                            <li>
                              <div
                                className="share_btn"
                                onClick={copyFacebookLink}
                              >
                                <img src={copyIcon} alt="copy" />
                              </div>
                            </li>
                            <li>
                              <button
                                class="share_btn"
                                type="button"
                                id="shareDropdownButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={shareIcon} alt="copy" />
                              </button>
                              <ul
                                class="share_dropdown dropdown-menu"
                                aria-labelledby="shareDropdownButton"
                              >
                                <li className="share_title text-center">
                                  Share link via{" "}
                                </li>
                                <li className="social_list">
                                  <span>
                                    <div>
                                      <img
                                        src={whatsappIcon}
                                        onClick={shareOnWhatsAppFace}
                                        alt="whatsappIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={fbSmIcon}
                                        onClick={shareOnFacebookFace}
                                        alt="fbIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={twitterIcon}
                                        onClick={shareOnTwitterIconFace}
                                        alt="twitterIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : null}

                        {meetDetails?.twitter_link ? (
                          <ul className="meet_media mb-2">
                            <li>
                              <img src={twitterIcon} alt="play" />
                            </li>
                            <li 
                            // onClick={() => handleOpenModal(meetDetails?.twitter_link, 'twitter')}
                            >
                              <Link
                              to={meetDetails?.twitter_link}
                              className="link_control"
                              target="_blank"
                              onClick={() =>
                                handleClickcount({
                                  meeting_id: meetDetails.id,
                                  channel: "TWITTER",
                                })
                              }
                            >
                              {meetDetails?.twitter_link}
                              </Link>{" "}
                            </li>
                            <li>
                              <div
                                className="share_btn"
                                onClick={copyTwitterLink}
                              >
                                <img src={copyIcon} alt="copy" />
                              </div>
                            </li>
                            <li>
                              <button
                                class="share_btn"
                                type="button"
                                id="shareDropdownButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={shareIcon} alt="copy" />
                              </button>
                              <ul
                                class="share_dropdown dropdown-menu"
                                aria-labelledby="shareDropdownButton"
                              >
                                <li className="share_title text-center">
                                  Share link via{" "}
                                </li>
                                <li className="social_list">
                                  <span>
                                    <div>
                                      <img
                                        src={whatsappIcon}
                                        onClick={shareOnWhatsAppTwitter}
                                        alt="whatsappIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={fbSmIcon}
                                        onClick={shareOnFacebookTwitter}
                                        alt="fbIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                  <span>
                                    <div>
                                      <img
                                        src={twitterIcon}
                                        onClick={shareOnTwitterIconTwitter}
                                        alt="twitterIcon"
                                      />{" "}
                                    </div>{" "}
                                  </span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : null}
                      </div>
                      {!isAdmin && (
                        <div className="meet_description mb-4">
                          <h3 className="meet_description-title">Report</h3>

                          <div className="show_reports">
                            <div className="mt-3 mb-3">
                              <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="profile_form "
                              >
                                <select
                                  name="report"
                                  id="report"
                                  className="w-50"
                                  {...register("report", { required: true })}
                                  onChange={handleDropdownChange}
                                >
                                  <option value="">Select report</option>
                                  {ReportData &&
                                    ReportData.map((data, index) => (
                                      <>
                                        <option
                                          key={index}
                                          value={`${data.id}|${data.report_title}`}
                                          id={data.id}
                                        >
                                          {data.report_title}
                                        </option>
                                      </>
                                    ))}
                                </select>

                                {errors.report && (
                                  <span className="error-danger">
                                    Select report
                                  </span>
                                )}
                                <div className="mt-3 position-relative mb-4">
                                  <textarea
                                    name="comments"
                                    className="form-control w-50 mobile_input"
                                    id="comments"
                                    placeholder="Enter your comments..."
                                    {...register("comments", { required: false })}
                                  />
                                  <label htmlFor="" className="report-optional">
                                    Optional
                                  </label>
                                </div>

                                {/* {showTextarea && (
                                                                <div className='mt-3'>
                                                                    <textarea name="comments" className='form-control w-50' id="comments" placeholder="Enter your comments..." {...register("comments", { required: true })} />
                                                                </div>
                                                            )} */}
                                {/* {errors.comments && (
                                <span className="error-danger">
                                  Enter your comments
                                </span>
                              )} */}
                              <div className="d-flex align-items-center gap-2">
                                <button
                                  className="d-block btn form_save-btn mt-3"
                                  type="submit"
                                >
                                  Submit Report
                                </button>
                                <button
                                  className="d-block btn form_save-btn mt-3"
                                  type="button"
                                  onClick={handleSendWhatsapp}
                                >
                                  Send via WhatsApp
                                </button>
                                </div>
                                
                              </form>
                            </div>
                            <div className=""></div>
                          </div>
                        </div>
                      )}</>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}
      </div>

      {isModalOpen && embedLink && (
        <Modal embedLink={embedLink} socialType={socialType} onClose={handleCloseModal} />
      )}

    </>
  );
}

export default MeetingDetail;
