import React, { useEffect, useRef, useState } from "react";
import "./videos.css";
// import "bootstrap-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import $ from "jquery";
import { CiSearch } from "react-icons/ci";
import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import videoImg from "../../assets/images/video_thum.png";
import playIcon from "../../assets/images/play_icon.svg";
import fbIcon from "../../assets/images/fb_icon.svg";
import twitterIcon from "../../assets/images/twitter_icon.svg";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import Api from "../Api/Api";
import { Link } from "react-router-dom";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-default/dist/all.css";
import { IoCloseCircle, IoGrid } from "react-icons/io5";
import { FaList } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import Datepickercomponent from "../util/Datepicker";
import VideoDuration from "../util/VideoDuration";
import debounce from "lodash.debounce";
import SkeletonLoader from "../util/Skeleton-Product";
import Modal from "../LiveVideos/Modal";
function VideoBlog() {
  const moment = require("moment");
  const [isVisible, setIsVisible] = useState(false);
  const [VideoData, setVideoData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    MeetingList,
    AddFavoriteVideo,
    RemoveFavoriteVideo,
    AddLikeVideo,
    RemoveLikeVideo,
    count_channel_click,
  } = Api();
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const User_ID = localStorage.getItem("user_id");
  const [currentPage, setCurrentPage] = useState(1);

  const [TotalCount, setTotalCount] = useState("");
  const [PerPageCount, setPerPageCount] = useState("");
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const indexOfFirstRow = Math.max(indexOfLastRow - rowsPerPage, 0);
  const dataTableRef = useRef(null);
  //   const fromDateRef = useRef(null);

  // Function to handle date change
  //from date
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectfromdate, setSelectedDate] = useState(null);
  const [selecttodate, setselectTodate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromtimevalue, setFromtimevalue] = useState(null);
  const [totimevalue, setTotimevalue] = useState(null);

  const handleDateChange = (date) => {
    if (date) {
      // setSelectedDate(null);
      // const formatdate = moment(date).format("DD/MM/YY");
      setSelectedDate(date);
      setCurrentPage(1);
      handleFilterChange({
        meeting: searchQuery,
        status: selectedStatus,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: date,
        selecttodate: selecttodate,
        currentPage: currentPage,
      });
    }
  };

  //to dates

  // console.log(selecttodate);
  const handleTodate = (date) => {
    // const formatdate = moment(date).format("DD/MM/YY");
    setselectTodate(date);
    setCurrentPage(1);
    handleFilterChange({
      meeting: searchQuery,
      status: selectedStatus,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: date,
      currentPage: currentPage,
    });
  };

  //   const toDateRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [clickvideos, setvideos] = useState(false);
  const [likeCount, setLikeCount] = useState(12);
  const [checkedState, setCheckedState] = useState({});
  const [loading, setLoading] = useState(true);
  const [MeetingData, setMeetingData] = useState([]);
  const [StartCount, setStartCount] = useState("");

  const user_role = localStorage.getItem("user_role");

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    handleFilterChange({
      meeting: searchQuery,
      status: selectedStatus,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage + 1,
    });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      handleFilterChange({
        meeting: searchQuery,
        status: selectedStatus,
        currentPage: currentPage - 1,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
      });
    }
    if (currentPage === 0) {
      setCurrentPage(1);
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handleCheckboxChange = (videoId) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId], // Toggle the checked state for the given videoId
    }));
  };

  const handleLikeChange = (videoId) => {
    setLikeCount((prevState) => ({
      ...prevState,
      [videoId]: prevState[videoId], // Increment like count for the given videoId
    }));
  };

  const [likedVideos, setLikedVideos] = useState({});
  const [favoritedVideos, setFavoritedVideos] = useState({});

  const defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);

  // Update the liked status of a video

  const handleLikeChangee = async ({ videoId, videocheck }) => {
    setLikedVideos((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));
    try {
      if (videocheck === 0) {
        await AddLikeVideo(videoId);
        handleFilterChange({
          meeting: searchQuery,
          fromTime: fromTime,
          toTime: toTime,
          selectfromdate: selectfromdate,
          selecttodate: selecttodate,
          currentPage: currentPage,
          selectedStatus: selectedStatus,
        });
        // console.log("wk");
      } else {
        await RemoveLikeVideo(videoId);
        handleFilterChange({
          meeting: searchQuery,
          fromTime: fromTime,
          toTime: toTime,
          selectfromdate: selectfromdate,
          selecttodate: selecttodate,
          currentPage: currentPage,
          selectedStatus: selectedStatus,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Update the favorited status of a video

  const handleFavoriteChange = async ({ videoId, videocheck }) => {
    setFavoritedVideos((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));

    // const isFavorited = !favoritedVideos[videoId];
    // ;
    try {
      if (videocheck === 0) {
        await AddFavoriteVideo(videoId);
        handleFilterChange({
          meeting: searchQuery,
          fromTime: fromTime,
          toTime: toTime,
          selectfromdate: selectfromdate,
          selecttodate: selecttodate,
          currentPage: currentPage,
          selectedStatus: selectedStatus,
        });
        // console.log("wk");
      } else {
        await RemoveFavoriteVideo(videoId);
        handleFilterChange({
          meeting: searchQuery,
          fromTime: fromTime,
          toTime: toTime,
          selectfromdate: selectfromdate,
          selecttodate: selecttodate,
          currentPage: currentPage,
          selectedStatus: selectedStatus,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFromTimeChange = (event) => {
    const selectedTime = event.target.value;
    setFromtimevalue(selectedTime);

    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setFromTime(formattedTime);
    handleFilterChange({
      meeting: searchQuery,
      status: selectedStatus,
      fromTime: formattedTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };

  const handleToTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTotimevalue(selectedTime);

    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setToTime(formattedTime);
    handleFilterChange({
      meeting: searchQuery,
      status: selectedStatus,
      fromTime: fromTime,
      toTime: formattedTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };

  const handleClickcount = async ({ channel, meeting_id }) => {
    await handleCount({
      user_id: User_ID,
      channel: channel,
      meeting_id: meeting_id,
    });
  };
  const handleCount = async ({ user_id, channel, meeting_id }) => {
    let values = {
      user_id: user_id,
      channel: channel,
      meeting_id: meeting_id, //vedio id
    };

    try {
      const response = await count_channel_click(values);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = async ({
    status,
    selectfromdate,
    selecttodate,
    fromTime,
    toTime,
    currentPage,
    meeting,
  }) => {
    try {
      const statusValue = document.getElementById("status").value;
      setLoading(true);

      const fromDate = selectfromdate
        ? moment(selectfromdate).format("DD/MM/YYYY")
        : "";

      const toDate =
        selecttodate && moment(selecttodate).isValid()
          ? moment(selecttodate).format("DD/MM/YYYY")
          : "";

      const filters = {
        status: status,
        fromDate: fromDate,
        toDate: toDate,
        fromTime: fromTime ? fromTime : "",
        toTime: toTime ? toTime : "",
        page: currentPage.toString(),
        meet: meeting,
      };
      const response = await MeetingList(filters);
      setVideoData(response.parameters.data);
      setMeetingData(response.parameters.data);
      setTotalCount(response.parameters.total);
      setPerPageCount(response.parameters.to);
      setStartCount(response.parameters.from);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during filtering meetings:", error);
    }
  };
  const [first, setFirst] = useState(true);
  const handlePage = async () => {
    if (first) {
      try {
        const filters = {
          status: "",
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
          page: "1",
        };

        const response = await MeetingList(filters);
        if (response) {
          setFirst(false);
        }
        setVideoData(response.parameters.data);
        setMeetingData(response.parameters.data);
        setTotalCount(response.parameters.total);
        setPerPageCount(response.parameters.to);
        setStartCount(response.parameters.from);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setFirst(true);
        console.error("Error occurred during filtering meetings:", error);
      }
    }
  };
  useEffect(() => {
    handlePage();
  }, [first]);

  const handleResetFilters = () => {
    document.getElementById("status").value = "";

    setSelectedStatus("");
    setSelectedDate(null);
    setselectTodate(null);
    setFromTime(null);
    setToTime(null);
    setCurrentPage(1);
    handleFilterChange({
      meeting: "",
      status: "",
      fromTime: null,
      toTime: null,
      selectfromdate: null,
      selecttodate: null,
      currentPage: "1",
    });
    setTotimevalue(null);
    setFromtimevalue(null);
  };
  const handleStatus = async (event) => {
    setSelectedStatus(event.target.value);

    await handleFilterChange({
      meeting: searchQuery,
      status: event.target.value,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };
  const debouncedSetSearchQuery = debounce((value) => {
    setSearchQuery(value);
    setCurrentPage(1);
    handleFilterChange({
      meeting: value,
      status: selectedStatus,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: "0",
    });
  }, 300);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    debouncedSetSearchQuery(value);
  };






  const [embedLink, setEmbedLink] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [socialType, setSocialType] = useState(null);
  const handleOpenModal = (link, type) => {
    let embedUrl = null;

    if (type === 'youtube') {
      const videoId = link?.split('v=')[1]?.split('&')[0];
      if (videoId) {
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (type === 'facebook') {
      embedUrl = link; 
      // const eventId = link?.split('/videos/')[1]?.split('/')[0];
      const eventId = link?.split('/').pop();
      if (eventId) {
        embedUrl = eventId;
        console.log('Event ID:', eventId); // Logs the event ID for further use
      }
    } else if (type === 'twitter') {
      embedUrl = link;
    }

    if (embedUrl) {
      setEmbedLink(embedUrl);
      setSocialType(type);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEmbedLink(null);
  };



  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align">
              <div className="page_title">Meetings</div>
              <div
                className="mobile_filter_icon mob_fills_icon"
                onClick={toggleVisibility}
              >
                <img src={filter_icon} alt="filter" />
              </div>
            </div>
          </div>
        </div>

        <div className="row desktop_filter">
          <div className="col-12">
            <form>
              <div className={`filter_container ${isOpen ? "open" : ""}`}>
                <div
                  className="filter_icon filter_col sm_flex-end hide_mobile"
                  onClick={toggleVisibility}
                >
                  <img src={filter_icon} alt="filter" className="" />
                  {/* <IoClose className="show_mobile filter_close" /> */}
                </div>

                <div className="close_sidemenu_mobile" onClick={handleClose}>
                  {isOpen && (
                    <IoCloseCircle
                      className="close_icon_mobile_response"
                      onClick={handleClose}
                    />
                  )}
                </div>
                <div className="fw-bold filter_title Mobile-mt-5 filter_col d-flex align-items-center justify-content-center">
                  <div className="name_filter position-relative w-100">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search"
                      // {...register("search")}
                      onChange={handleSearchChange}
                    />
                    <span>
                      <CiSearch />
                    </span>
                  </div>
                </div>
                <div className="status_filter filter_col">
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={handleStatus}
                  >
                    <option value="">Status</option>
                    <option value="UPCOMING">Upcoming</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="LIVE">Live</option>
                  </select>
                </div>
                <div className="from_date-filter filter_col date_picker">
                  {/* <input
                    // ref={fromDateRef}
                    type="text"
                    className="form-control border-0"
                    placeholder="From Date"
                    name="FromDate"
                    onChange={handleFromDateChange}
                  /> */}
                  {/* <DatePicker
                    selected={selectfromdate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/YYYY"
                    placeholderText="From Date"
                  /> */}
                  <Datepickercomponent
                    selecteddate={selectfromdate}
                    handleDate={handleDateChange}
                    placeholderText="From Date"
                  />
                </div>
                <div className="to_date-filter filter_col date_picker">
                  {/* <input
                    // ref={toDateRef}
                    type="text"
                    className="form-control border-0"
                    placeholder="To Date"
                    name="ToDate"
                    onChange={handleToDateChange}
                  /> */}
                  {/* <DatePicker
                    selected={selecttodate}
                    onChange={handleTodate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="To Date"
                  /> */}
                  <Datepickercomponent
                    selecteddate={selecttodate}
                    handleDate={handleTodate}
                    placeholderText="To Date"
                  />
                </div>
                <div className="from_time-filter filter_col p-0 down_arrow-none">
                  {/* <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} /> */}
                  <TimePicker
                    title={fromTime}
                    value={fromtimevalue}
                    format="HH:mm"
                    placeholder="From Time"
                    name="FromTime"
                    className="form-control border-0 h-100 w-100"
                    onChange={handleFromTimeChange}
                  />
                </div>
                <div className="to_time-filter filter_col p-0 down_arrow-none">
                  {/* <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} /> */}
                  <TimePicker
                    title={toTime}
                    value={totimevalue}
                    format="HH:mm"
                    placeholder="To Time"
                    name="ToTime"
                    className="form-control border-0 h-100 w-100"
                    onChange={handleToTimeChange}
                  />
                </div>
                <div
                  className="reset_filter filter_col d-flex align-items-center justify-content-center"
                  onClick={handleResetFilters}
                >
                  <img src={reload_icon} alt="reset" className="me-2" /> Reset
                  Filter
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {VideoData.length > 0 && (
              <ul class="nav nav-tabs videos_tab justify-content-center border-0">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#gridView"
                    onClick={(e) => e.preventDefault()}
                  >
                    <IoGrid />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#listView"
                    onClick={(e) => e.preventDefault()}
                  >
                    <FaList />
                  </a>
                </li>
              </ul>
            )}

            <div className="tab-content mt-4">
              <div className="tab-pane container active" id="gridView">
                <div className="row">
                  {loading ? <SkeletonLoader /> : <> {VideoData && VideoData.length > 0 ? (
                    VideoData.map((video, index) => (
                      <>
                        <div
                          className="col-12 col-sm-6 col-md-4 mb-3 mob_padding"
                          key={video.id}
                        >
                          <div className="video_blog-card">
                            <div className="video_banner mb-3 position-relative">
                              <Link
                                to={`/user/videos/meeting-detail/${video.id}`}
                                className="text-decoration-none"
                              >
                                {video.cover_link && <img
                                  src={video.cover_link}
                                  alt={video.video_title}
                                />}
                                {!video.cover_link && <img src={videoImg} />}
                              </Link>
                              <label
                                className="like_video"
                                htmlFor={video.id + "Favorite"}
                              >
                                <input
                                  id={video.id + "Favorite"}
                                  type="checkbox"
                                  checked={video.is_users_favorite === 1}
                                  onChange={() =>
                                    handleFavoriteChange({
                                      videoId: video.id,
                                      videocheck: video.is_users_favorite,
                                    })
                                  }
                                />
                                {video.is_users_favorite === 1 ? (
                                  <IoMdHeart />
                                ) : (
                                  <IoMdHeartEmpty />
                                )}
                              </label>
                              <label
                                className="thump_like"
                                htmlFor={video.id + "Like"}
                              >
                                <input
                                  id={video.id + "Like"}
                                  type="checkbox"
                                  checked={likedVideos[video.id]}
                                  onChange={() =>
                                    handleLikeChangee({
                                      videoId: video.id,
                                      videocheck: video.is_user_liked,
                                    })
                                  }
                                />
                                {video.is_user_liked === 1 ? (
                                  <AiFillLike className="like_icon" />
                                ) : (
                                  <AiOutlineLike className="like_icon" />
                                )}
                                <span className="like_count">
                                  {video.total_likes}
                                </span>
                              </label>
                            </div>
                            <div className="video_description mb-3">
                              <Link
                                to={`/user/videos/meeting-detail/${video.id}`}
                                className="text-decoration-none"
                              >
                                <div className="video_title">
                                  {video.video_title}
                                </div>
                                <div className="video_duration d-flex">
                                  <VideoDuration
                                    from_time={
                                      video.from_time
                                        ? video.from_time
                                        : "00:00"
                                    }
                                    to_time={
                                      video.to_time ? video.to_time : "00:00"
                                    }
                                  />
                                  <span className="px-2"></span>(
                                  {video.from_time
                                    ? moment(
                                      video.from_time,
                                      "HH:mm:ss"
                                    ).format("HH:mm")
                                    : "00:00"}
                                  -
                                  {video.to_time
                                    ? moment(video.to_time, "HH:mm:ss").format(
                                      "HH:mm"
                                    )
                                    : "00:00"}
                                  )
                                </div>
                              </Link>
                            </div>
                            <div className="video_other d-flex justify-content-between align-items-center">
                              <div className="video_date">
                                Date : {video.video_date}
                              </div>
                              <div className="video_social-media">
                                <ul className="action_list">
                                  {video.youtube_link && (
                                    <li onClick={() => handleOpenModal(video.youtube_link, 'youtube')}>
                                      {/* <Link
                                        to={video.youtube_link}
                                        target="_blank"
                                        onClick={() =>
                                          handleClickcount({
                                            meeting_id: video.id,
                                            channel: "YOUTUBE",
                                          })
                                        }
                                      > */}
                                        <img src={playIcon} alt="play" />
                                      {/* </Link> */}
                                    </li>
                                  )}
                                  {video.facebook_link && (
                                    <li 
                                    onClick={() => handleOpenModal(video?.facebook_link, 'facebook')}
                                    >
                                      {/* <Link
                                        to={video.facebook_link}
                                        target="_blank"
                                        onClick={() =>
                                          handleClickcount({
                                            meeting_id: video.id,
                                            channel: "FACEBOOK",
                                          })
                                        }
                                      > */}
                                        <img src={fbIcon} alt="facebook" />
                                      {/* </Link> */}
                                    </li>
                                  )}
                                  {video.twitter_link && (
                                    <li 
                                    // onClick={() => handleOpenModal(video?.twitter_link, 'twitter')}
                                    >
                                      <Link
                                        to={video.twitter_link}
                                        target="_blank"
                                        onClick={() =>
                                          handleClickcount({
                                            meeting_id: video.id,
                                            channel: "TWITTER",
                                          })
                                        }
                                      >
                                        <img src={twitterIcon} alt="twitter" />
                                      </Link>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div>
                              <span className="video_date pe-2">Status :</span>
                              <button
                                className={
                                  video.status === "COMPLETED"
                                    ? "rounded-left rounded-right rounded-top rounded-bottom custom_btn-success ws-75"
                                    : video.status === "UPCOMING"
                                      ? "rounded-left rounded-right rounded-top rounded-bottom custom_btn-info ws-75"
                                      : "rounded-left rounded-right rounded-top rounded-bottom custom_btn-warning ws-75"
                                }
                              >
                                {video.status === "COMPLETED"
                                  ? "Completed"
                                  : video.status === "UPCOMING"
                                    ? "Upcoming"
                                    : "Live"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <h6> No meetings found for the selected filters.</h6>
                  )}</>}
                </div>
                {VideoData.length > 0 && (
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0 table_page-count">
                      Showing {StartCount}-{PerPageCount} of {TotalCount}
                    </p>
                    <div className="d-flex align-items-center justify-content-end table_nav">
                      <button
                        disabled={currentPage === 1 || currentPage === 0}
                        onClick={handlePrevPage}
                      >
                        <FaAngleLeft />
                      </button>
                      <button
                        disabled={PerPageCount == TotalCount}
                        onClick={handleNextPage}
                      >
                        <FaAngleRight />
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div class="tab-pane container " id="listView">
                <div className="row mob_desk_margin">
                  <div className="col-12">
                    <div className="table-responsive main_datatable">
                      <table
                        ref={dataTableRef}
                        className="table custom_datatable meeting_table"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th className="text-center">ID</th>
                            <th className="text-start">DATE</th>
                            <th className="text-start">TITLE</th>
                            <th className="text-start">START TIME</th>
                            <th className="text-start">END TIME</th>
                            <th className="text-start">STATUS</th>
                            <th className="text-center">ACTION</th>
                          </tr>
                        </thead>

                        {loading ? (
                          // Loader component or loading indicator
                          <tbody>
                            <tr>
                              <td colSpan="7" className="text-center">
                                Loading...
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {MeetingData && MeetingData.length > 0 ? (
                              MeetingData.map((meet, index) => (
                                <tr key={meet.id}>
                                  <td className="text-center">
                                    {indexOfFirstRow + index + 1}
                                  </td>
                                  <td className="text-start white-space-nowrap">
                                    {meet.video_date}
                                  </td>
                                  <td className="text-start">
                                    <Link
                                      to={
                                        user_role === "ADMIN"
                                          ? `/admin/meetings/meeting-detail/${meet.id}`
                                          : `/user/videos/meeting-detail/${meet.id}`
                                      }
                                    >
                                      {" "}
                                      {meet.video_title}
                                    </Link>
                                  </td>
                                  <td className="text-center">
                                    {meet.from_time
                                      ? moment(
                                        meet.from_time,
                                        "HH:mm:ss"
                                      ).format("HH:mm")
                                      : "00:00"}
                                  </td>
                                  <td className="text-center">
                                    {meet.to_time
                                      ? moment(meet.to_time, "HH:mm:ss").format(
                                        "HH:mm"
                                      )
                                      : "00:00"}
                                  </td>
                                  <td>
                                    <button
                                      className={
                                        meet.status === "COMPLETED"
                                          ? "btn custom_btn-success ws-75"
                                          : meet.status === "UPCOMING"
                                            ? "btn custom_btn-info ws-75"
                                            : "btn custom_btn-warning ws-75"
                                      }
                                    >
                                      {meet.status === "COMPLETED"
                                        ? "Completed"
                                        : meet.status === "UPCOMING"
                                          ? "Upcoming"
                                          : "Live"}
                                    </button>
                                  </td>
                                  <td>
                                    <ul className="action_list">
                                      {meet.youtube_link && (
                                        <li onClick={() => handleOpenModal(meet.youtube_link, 'youtube')}>
                                          {/* <Link
                                            to={meet.youtube_link}
                                            target="_blank"
                                            onClick={() =>
                                              handleClickcount({
                                                meeting_id: meet.id,
                                                channel: "YOUTUBE",
                                              })
                                            }
                                          > */}
                                            <img src={playIcon} alt="play" />
                                          {/* </Link> */}
                                        </li>
                                      )}
                                      {meet.facebook_link && (
                                        <li onClick={() => handleOpenModal(meet?.facebook_link, 'facebook')}>
                                          {/* <Link
                                            to={meet.facebook_link}
                                            target="_blank"
                                            onClick={() =>
                                              handleClickcount({
                                                meeting_id: meet.id,
                                                channel: "FACEBOOK",
                                              })
                                            }
                                          > */}
                                            <img src={fbIcon} alt="facebook" />
                                          {/* </Link> */}
                                        </li>
                                      )}
                                      {meet.twitter_link && (
                                        <li>
                                          <Link
                                            to={meet.twitter_link}
                                            target="_blank"
                                            onClick={() =>
                                              handleClickcount({
                                                meeting_id: meet.id,
                                                channel: "TWITTER",
                                              })
                                            }
                                          >
                                            <img
                                              src={twitterIcon}
                                              alt="twitter"
                                            />
                                          </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No meetings found for the selected filters.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    {MeetingData.length > 0 && (
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 table_page-count">
                          Showing {StartCount}-{PerPageCount} of {TotalCount}
                        </p>
                        <div className="d-flex align-items-center justify-content-end table_nav">
                          <button
                            disabled={currentPage === 1 || currentPage === 0}
                            onClick={handlePrevPage}
                          >
                            <FaAngleLeft />
                          </button>
                          <button
                            disabled={PerPageCount == TotalCount}
                            onClick={handleNextPage}
                          >
                            <FaAngleRight />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && embedLink && (
        <Modal embedLink={embedLink} socialType={socialType} onClose={handleCloseModal} />
      )}


    </div>
  );
}

export default VideoBlog;
